import { makeStyles } from '@material-ui/core/styles';
import colors from '../../themes/colors';

export default makeStyles((theme) => ({
  button: {
    background: colors.PRIMARY + ' !important',
  }
}))


